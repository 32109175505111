<template>
  <header class="bg-rose-700 fixed top-0 left-0 w-full z-50">
    <nav class="flex mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8 " aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 mb-0 p-1.5">
          <span class="sr-only">Your Company</span>
          <img class="h-8 w-auto" src="../assets/favicon.png" alt="" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-12">
        <router-link to="/" class="text-sm font-semibold leading-6 text-gray-200">HomePage</router-link>
        <router-link to="/about" class="text-sm font-semibold leading-6 text-gray-200">About</router-link>
        <router-link to="/database" class="text-sm font-semibold leading-6 text-gray-200">Downloads</router-link>
      </PopoverGroup>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <!-- <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a> -->

          <div v-if="isAuthenticated">
                <button @click="onClickSignOut" class="text-sm font-semibold leading-6 text-gray-200">
                  Log out <span aria-hidden="true">&rarr;</span>
                </button>
              </div>
              <div v-else >
              <button @click="onClickSignIn" class="text-sm font-semibold leading-6 text-gray-200">
                  Log in <span aria-hidden="true">&rarr;</span>
              </button>
        </div>
      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <router-link to="/" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">HomePage</router-link>
              <router-link to="/about" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">About</router-link>
              <router-link to="/database" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Downloads</router-link>
            </div>
            <div class="py-6">
              <!-- <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
              <button @click="onClickSignIn" class="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                Sign In
              </button> -->
              <div v-if="isAuthenticated">
                <div @click="onClickSignOut"  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  <button @click="onClickSignOut" >
                  Log out
                  </button>
                </div>
              </div>
              <div v-else >
                <div  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-white">
              <button @click="onClickSignIn" class="diable">
                  Log in
              </button>
            </div>
              </div>

            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  PopoverGroup,

} from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

const mobileMenuOpen = ref(false)


import { useLogto } from "@logto/vue";
import { redirectUrl } from '../consts/logto';

const { signIn, signOut, isAuthenticated } = useLogto();
const onClickSignIn = () => signIn(redirectUrl);

const onClickSignOut = async () => {
    signOut(redirectUrl);
};
</script>