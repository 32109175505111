<template>
    <div class="pt-14 bg-cyan-600 text-white"> <!-- 添加深色背景和白色文字 -->
        <div class="flex flex-col items-start pl-20 pr-20 ml-10 mr-10 pt-20 pb-5"> <!-- 使用 Flexbox 实现左对齐，并添加左内边距 -->
        <h1 class ='font-bold text-5xl pb-5'>Magnetic Materials</h1>
        <p class = 'text-left text-lg'>
            Powered by advanced large language models, our project introduces a magnetic materials database aimed at accelerating the discovery of new materials. This AI-driven platform offers open web-based access to a vast collection of information on magnetic materials, all extracted and synthesized from scientific literature. It serves as a pivotal resource for researchers and innovators seeking to explore and design novel magnetic materials by providing direct access to curated data derived from the forefront of AI technology        
        </p>
        <div class="flex justify-between gap-x-4 md:gap-x-3 sm:gap-x-2 w-1/7 mt-0">
            <button @click="onClickSignIn" class="bg-white text-cyan-600 font-bold py-2 px-4 rounded mt-5">Register or Login</button>
            <button class="bg-white text-cyan-600 font-bold py-2 px-4 rounded mt-5"  @click="navigateToRandomSample">See a Random Material</button>
            <!-- add a materials number -->
            <span class="text-pink-100 font-semibold py-2 px-4 mt-5 inline-flex items-center border-2 border-gray-600 rounded">
                Materials Number: {{ materials_number.toLocaleString()}}
            </span>
        </div>

        </div>
        <!-- materials number -->



    </div>

    <div class="pt-14 bg-red-50 text-white"> <!-- 添加深色背景和白色文字 -->
    <PeriodicTable></PeriodicTable>
    </div>
</template>
  

<script>
import PeriodicTable from '../components/PeriodicTable.vue'
import { useLogto } from "@logto/vue";
import { redirectUrl } from '../consts/logto';
export default {
    name: 'HomePage',
    components: {
        // SignIn,
        // SingOut
        PeriodicTable
    },
    data() {
      return {
        materials_number: 0, // 用于存储获取的 JSON 数据
      };
    },
    mounted() {
        this.fetchMaterialsNum(); // 组件加载时调用方法获取材料数量
    },
    setup() {
        const { signIn } = useLogto();
        const onClickSignIn = () => signIn(redirectUrl);
        return { onClickSignIn };
    },
    methods: {
    navigateToRandomSample() {
      // 使用编程式导航跳转到 /randomsample 路径
      this.$router.push('/randomsample');
    },
    async fetchMaterialsNum() {
        try {
          const url = '/backend/number'; // 您的 JSON 数据源地址
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          this.materials_number = Number(await response.text());
        } catch (error) {
          console.error('There has been a problem with your fetch operation:', error);
        }
      },
    formatNumber(number) {
        return number.toLocaleString();
    },
  },
}
</script>
